import "./style.scss";

import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next/dist/es/useTranslation";
import AccentContainer from "../../components/Container/_accent";
import Comments from "../../components/Comments";
import Container from "../../components/Container";
import ContentWithSidebar from "../../components/Container/_contentWithSidebar";
import Heading from "../../components/Heading";
import Layout from "..";
import Mark from "../../components/Marker";
import Meta from "../../components/Post/_meta";
import Paragraph from "../../components/Paragraph";
import PreviewPost from "../../components/Post/_preview";
import ScrollUp from "../../components/ScrollUp";
import Sidebar from "../../components/Sidebar";
import SocialBar from "../../components/Post/_socialsBar";
import SocialNetwork from "../../components/SocialsNetwork";
import Title from "../../components/Title";
import TranslationWord from "../../components/Translate_component";
import SubscribeForm from "../../components/Forms/_subscribe";
import { canonicalLink } from "../../components/seo";
import { createClassName } from "../../helpers";
import { postRenderComToolkit } from "./mdComponents";

import { renderCom } from "../../components/ForMarkDownComponents";
import { useImageRendering } from "../../components/Image";
import { useStaticQuery } from "gatsby";

const cn = createClassName("post");

const views = <TranslationWord text="views" />;

const PostPage = ({
  frontmatter,
  htmlAst,
  allPagesComments,
  relatedPosts,
  ...props
}) => {
  const Image = useImageRendering(props);
  const postRenderCom = renderCom({ ...postRenderComToolkit, img: Image });
  const { t } = useTranslation("strings");
  const [commentsLength, setCommentsLength] = useState(0);
  const [view, setViews] = useState(0);

  const getViewsCount = async () => {
    await fetch(`${process.env.GATSBY_API_URL}/page-views`)
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(response.status, response.statusText);
      })
      .then(res => setViews(res))
      .catch(() => setViews(null));
  };

  useEffect(() => {
    getViewsCount();
  }, [frontmatter.seo[0].link]);

  const links = frontmatter.hashtags.map((item, index) => (
    <span key={index} className={cn("page", { links: "link" })}>
      {`${item} `}
    </span>
  ));

  const valueForCommentsLengthText = number =>
    number > 20 && number % 10 !== 0 ? number % 10 : number;

  const russianCommentsLengthText = value =>
    `${value} ${t("common:comments_interval", {
      postProcess: "interval",
      count: valueForCommentsLengthText(value),
    })}`;

  return (
    <Layout {...frontmatter.seo[0]} isArticle image={frontmatter.coverImageUrl}>
      <section aria-label="Header banner">
        <AccentContainer>
          <Container className={cn("container")}>
            {frontmatter.category && (
              <Mark
                text={frontmatter.category}
                textClassName={cn("banner", { marker: "text" })}
                className={cn("banner", { marker: true })}
                color="white"
              />
            )}
            <Heading
              level={1}
              weight="black"
              color="white"
              className={cn("banner", { title: true })}
            >
              <>
                {frontmatter.bannerTitle} <br />
                {frontmatter.bannerTitleRemark && frontmatter.bannerTitleRemark}
              </>
            </Heading>
            <div className={cn("banner", { remark: true })}>
              <span className={cn("banner", { remark: "item", data: "title" })}>
                {frontmatter.author}
              </span>
              <Meta
                meta={frontmatter.defaultData[0].dataList}
                views={
                  view ? (
                    <>
                      {view}
                      &nbsp;
                      {views}
                    </>
                  ) : null
                }
                text={views}
                commentsLength={
                  commentsLength === 0 ? (
                    <TranslationWord text="no_comments" />
                  ) : (
                    russianCommentsLengthText(commentsLength)
                  )
                }
                className={cn("banner", { data: true })}
                itemClassName={cn("banner", { remark: "wrap" })}
                valueClassName={cn("banner", { remark: "item" })}
              />
            </div>
          </Container>
        </AccentContainer>
      </section>
      <Container>
        <Sidebar position="left" className={cn("sidebar", { wrap: true })}>
          <SocialNetwork
            title={frontmatter.bannerTitle}
            hashtags={frontmatter.hashtags}
            link={canonicalLink(frontmatter.seo[0].link)}
          />
        </Sidebar>
        <ContentWithSidebar
          className={cn("container", { "main-content": true })}
        >
          {postRenderCom(htmlAst)}
          <div className={cn("page", { links: "wrap" })}>
            <span className={cn("page", { links: "tags" })}>
              {t("common:tags")}
            </span>
            <div className={cn("page", { links: true })}> {links}</div>
          </div>
        </ContentWithSidebar>
      </Container>
      <Container className={cn("container", { "main-content": true })}>
        <Comments
          comments={allPagesComments.nodes}
          className={cn("comments")}
          countComments={setCommentsLength}
          commmentableType="Content"
          commmentableId={frontmatter.id}
        />
      </Container>
      {relatedPosts.length > 0 && (
        <Container className={cn("related", { wrap: true })}>
          <Title level={3}>{t("related-articles")}</Title>
          <div className={cn("related", { posts: true })}>
            {relatedPosts.map(post => (
              <div className={cn("related", { posts: "block" })} key={post.id}>
                <PreviewPost
                  // imageRenderer={Image}
                  {...post.childMarkdownRemark.frontmatter}
                  image={post.coverImage}
                  slug={post.slug}
                />
              </div>
            ))}
            <div className={cn("related", { "subscribe-form": true })}>
              <Title level={4} weight="medium">
                {t("forms:miniForm.heading")}
              </Title>
              <Paragraph
                bottomIndent
                className={cn("subscribe-form", { description: true })}
              >
                {t("forms:miniForm.description.forFooter")}
              </Paragraph>
              <SubscribeForm />
            </div>
          </div>
        </Container>
      )}
      <ScrollUp />
      <SocialBar
        title={frontmatter.bannerTitle}
        hashtags={frontmatter.hashtags}
        link={canonicalLink(frontmatter.seo[0].link)}
        className={cn("socialsBar", { mobile: true })}
      />
    </Layout>
  );
};

PostPage.propTypes = {
  frontmatter: PropTypes.object.isRequired,
  htmlAst: PropTypes.object.isRequired,
  allPagesComments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PostPage;
